body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Amplify UI コンポーネントのフォントサイズを調整する */
.amplify-form-section .amplify-heading {
  font-size: 1.5em; /* タイトルのフォントサイズを大きく */
}

.amplify-form-section .amplify-input {
  font-size: 1.2em; /* 入力フィールドのフォントサイズを大きく */
}

.amplify-form-section .amplify-button {
  font-size: 1.2em; /* ボタンのフォントサイズを大きく */
}
