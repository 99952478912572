/* 背景*/
.toppagecontainer {
  background-color: #ffffff;
  margin-top: 0px;
}


/* 一般的なコンテナ設定 */
.topcontainer {

  transform: scale(0.95);  /* この値は必要に応じて調整してください。1より小さい値で全体を縮小します。 */
    transform-origin: top;
    margin-top: 10px;
}
/* Surface Pro 7 の場合 */
@media screen and (min-width: 912px) and (max-width: 912px) and (min-height: 1368px) and (max-height: 1368px) {
  .topcontainer {
      transform: scale(0.9);
      margin-top: 10px;
  }
}
/* Surface Pro 7 の場合、横向き */
@media screen and (min-width: 1368px) and (max-width: 1368px) and (min-height: 912px) and (max-height: 912px) {
  .topcontainer {
    transform: scale(0.8);
    margin-top: 10px;
  }
}
/* iPad Air の場合 */
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
  .topcontainer {
      transform: scale(0.9);
      margin-top: 10px;
  }
}
/* iPad Air の場合、横向き */
@media screen and (min-width: 1180px) and (max-width: 1180px) and (min-height: 820px) and (max-height: 820px) {
  .topcontainer {
    transform: scale(0.9);
    margin-top: 10px;
  }
}


/* ヘッダーのスタイル */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  white-space: nowrap;
}

/* レシピリストのスタイル */
.recipe-list {
  list-style: none;
  padding: 0;
  white-space: nowrap;
}

/* 各レシピアイテムのスタイル */
.recipe-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  position: relative;
  
}

.recipe-list-item:first-child {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

/* サムネイルのスタイル */
.recipe-thumbnail {
  height: 100px;
  width: 100px;
  border: 1px solid #ddd;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;}

@media (max-width: 768px) {
  .recipe-thumbnail {
    height: 80px;
    width: 80px;}}
@media (max-width: 576px) {
  .recipe-thumbnail {
      height: 60px;
      width: 60px;}}


/* 画像なしテキスト */
.no-image {
  margin: 0;
  padding: 0;
  color: #aaa;
  font-style: italic;
  font-size: 16px;}

@media (max-width: 768px) {
  .no-image {font-size: 14px;}}
@media (max-width: 576px) {
  .no-image {font-size: 12px;}} 


/* レシピリンク、カテゴリー等のスタイル */
.recipe-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  align-items: flex-start;
  padding-left: 10px;}

@media (max-width: 768px) {
  .recipe-details {font-size: 1px;}}
@media (max-width: 576px) {
  .recipe-details {font-size: 1px;}}


/* txレシピ一覧 */
.recipe-title {
  white-space: nowrap;
  font-size: 24px;
  text-align: left;
  padding-left: 80px;}
  

@media (max-width: 768px) {
  .recipe-title {font-size: 20px;}}
@media (max-width: 576px) {
  .recipe-title {font-size: 16px;}}  


/* カテゴリーコンテナのスタイル */
.categories-container {
  
  display: flex;
  gap: 5px; /* 間隔を15pxに増やす */
  margin-top: -10px; /* マージンを0にすることで上に少し動かす */
}

/* カテゴリーラベルのスタイル */
.category-label {
  background-color: #e9f3b6;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 16px;
}

@media (max-width: 768px) {
    .category-label {font-size: 13px;}
}

@media (max-width: 576px) {
    .category-label {font-size: 9px;}
}
/* 状態ラベルのスタイル */
.status-label {
  background-color: #d8f1f9; /* 水色 */
}


/* レシピテキストリンクのスタイル */
.recipe-link {
  margin-bottom: -10px;}
.recipe-link a:hover {color: #0056b3;}
.recipe-link a {
  color: #7341e9; /* デフォルトのリンク色 */
  font-size: 24px; /* デフォルトのフォントサイズ */
  transition: color 0.3s ease;}/* 色の変化を滑らかに */



  .topgreenbtn {
    transform: scale(1.3);
    padding: 2px 1px;
    width: 85px; 
    height: 30px; 
    margin-right: -20px;   
    border: 1px solid rgb(139, 129, 129);
    background-color: #99e692 
}
.topredbtn {
  transform: scale(1);
  padding: 2px 1px;
  width: 75px; 
  height: 30px; 
  margin-right: 0px;
}
.topfont-size {
  font-size: 13px; 
  padding: 2px 1px;
  
}

.topredbtnfont {
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}


.topform-control {
  font-size: 13px; 
  padding: 2px 1px;
  transform: scale(1.1);
  margin-right: 50px;
  max-height: 200px;
    overflow-y: auto;
}