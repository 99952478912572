

.container {
    transform: scale(0.9);  /* この値は必要に応じて調整してください。1より小さい値で全体を縮小します。 */
    transform-origin: top;
    margin-left: -10px;
    box-sizing: content-box;
    margin-top: 5px;
}
/* 横向きのスタイル */
@media screen and (orientation: landscape) {
    .container {
        transform: scale(1);  /* この値は必要に応じて調整してください。1より小さい値で全体を縮小します。 */
        transform-origin: top;
        margin-left: 10vw;  /* ビューポートの幅の5%をマージンとして適用 */
        box-sizing: content-box;
        margin-top: 5px;
    }
}
/* Surface Pro 7 の場合 */
@media screen and (min-width: 912px) and (max-width: 912px) and (min-height: 1368px) and (max-height: 1368px) {
    .container {
        transform: scale(0.67);
        transform-origin: top;
        margin-left: 80px;
    }
}

/* iPad Air の場合 */
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
    .container {
        transform: scale(0.8);
        transform-origin: top;
    }
}

.recipegreenbtn1 {
    transform: scale(1.2);
    padding: 5px 1px;
    width: 40%; 
    height: 65%; 
    margin-right: -20px;
    margin: 5px;  /* 上下左右に5pxのマージンを追加 */
    top: -3px; /* この値を調整して要素を上に動かします */
    border: 1px solid rgb(139, 129, 129);
    background-color: #99e692 
}
.recipegreenbtn2 {
    transform: scale(1.2);
    padding: 6px 1px;
    width: 40%; 
    height: 70%; 
    margin-right: 0px;
    margin-top: 0px;
    position: relative;
    top: -5px; /* この値を調整して要素を上に動かします */
    
}

.recipegreenbtn3 {
    transform: scale(1.3);
    padding: 5px 1px;
    width: 60%; 
    height: 70%; 
    margin-right: 0px;
    margin-top: 10px;
    position: relative;
    top: -3px; /* この値を調整して要素を上に動かします */
    border: 1px solid rgb(139, 129, 129);
    background-color: #99e692 
}

.recipegreenbtn4 {
    transform: scale(1.3);
    padding: 5px 1px;
    width: 60%; 
    height: 70%; 
    margin-right: 0px;
    margin-top: 10px;
    position: relative;
    top: -3px; /* この値を調整して要素を上に動かします */
    border: 1px solid rgb(139, 129, 129);
    background-color: #99e692 
}

/* ボタンがホバーされたときのエフェクト */
.greenbtn:hover {
    background-color: #88d682 !important; /* ちょっと明るく */
    border-color: #d0d0cc !important;
}

/* ボタンがアクティブ（クリックされた）ときのエフェクト */
.recipegreenbtn4:active {
    transform: scale(0.95); /* ボタンを少し縮小 */
    transition: transform 0.1s; /* クリックエフェクトの速度調整 */
    border: 1px solid rgb(139, 129, 129);
}

.reciperedbtn1 {
    transform: scale(1.3);
    padding: 5px 1px;
    width: 60%; 
    height: 70%; 
    margin-right: 0px;
    margin-top: 10px;
    position: relative;
    top: -3px; /* この値を調整して要素を上に動かします */
    border: 1px solid rgb(139, 129, 129);
   
}


.no-gutters {
    white-space: nowrap;
    margin-right: 0;
    margin-left: 0;
    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.input-ingredient, .input-result, .input-next-amount {
    flex: 1.2; 
}

.adjustment-history {
    flex: 0.8; 
}

.double-width {
    flex-grow: 2;
}

.form-control {
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 5px; 
    margin-bottom: 5px; 
    height: 30px;
}

.form-label, .adjustable-font-size {
    font-size: 14px;
    margin-top: 5px; 
    margin-bottom: 5px; 
}

@media (max-width: 768px) {
    .form-control, .form-label, .adjustable-font-size, .toggle-label {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .form-control, .form-label, .adjustable-font-size, .toggle-label {
        font-size: 10px;
    }
}

.align-middle {
    vertical-align: middle !important;
}

.toggle-container {
    width: 140px; 
    height: 43px; 
    background-color: white;
    border: 2px solid #e0e0dd;
    border-radius: 20px; 
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px; 
    transition: all 0.3s;
    margin-top: 2px;  /* この値を調整して、トグルボタンの位置を微調整*/ 
    border-radius: 4px;  /* 楕円形から四角に変更 */
    border: 1px solid rgb(139, 129, 129);
}

.toggle-slider {
    width: 65px; 
    height: 40px; 
    background-color: #99e692;
    border-radius: 15px; 
    position: absolute;
    transition: all 0.3s;
    z-index: 1; 
    left: 0%;
    transform: translateX(0);
    border-radius: 4px;  /* 楕円形から四角に変更 */
    border: 2px solid rgb(183, 176, 176);
}

@media (max-width: 768px) {
    .toggle-container {
        width: 120px; 
        height: 36px; 
        margin-top: 3px;
    }
    .toggle-slider {
        width: 55px; 
        height: 31px; 
    }
}

@media (max-width: 576px) {
    .toggle-container {
        width: 100px; 
        height: 32px; 
        margin-top: 4px;
    }
    .toggle-slider {
        width: 45px; 
        height: 27px; 
    }
}

.toggle-label {
    width: 45%;  
    text-align: center;
    color: rgb(24, 23, 23) !important;
    font-size: 16px;
    opacity: 1; 
    z-index: 2;
    white-space: nowrap;
}
@media (max-width: 768px) {
    .toggle-label {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .toggle-label {
        font-size: 10px;
    }
}

.toggle-container.active .toggle-slider {
    left: 100%;  
    transform: translateX(-100%);  
}
/* トグルと履歴保存の間の空白 */
.ml-2 {
    margin-left: 1px !important;
}


/* 履歴保存ボタンのテキストサイズを調整 */
.btn.adjustable-font-size {
    font-size: 16px;
    padding-left: 5px;  /* 左側の内部スペース */
    padding-right: 10px; /* 右側の内部スペース */
    white-space: nowrap;
}


@media (max-width: 768px) {
    .btn.adjustable-font-size {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .btn.adjustable-font-size {
        font-size: 9px;
    }
}

.button-container {
    display: flex;
    align-items: center;
}
.adjustable-button-position {
    position: relative;
    top: 16px;  /* 10px上に移動 */
}











/* 削除ボタンと食材追加ボタンのスタイルを追加 */
/* デフォルトのフォントサイズ */
.btn.small-text-btn {
    font-size: 16px;
    white-space: nowrap;
    min-width: 50px;  /* この値は必要に応じて調整してください */
    padding: 0.25rem 0.5rem; /* 上下のパディングと左右のパディングを調整 */
}


@media (max-width: 768px) {
    .btn.small-text-btn {
        font-size: 16px;
    }
}

@media (max-width: 576px) {
    .btn.small-text-btn {
        font-size: 14px;
    }
}

/* フォーム内のテキスト */
@media (max-width: 768px) {
    .form-control, .form-label, .adjustable-font-size {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .form-control, .form-label, .adjustable-font-size {
        font-size: 12px;
    }
}

.form-control {
    padding: 0 2px; /* 上下のパディングを0、左右のパディングを5pxに設定 */
}



.no-wrap-btn {
    white-space: nowrap; /* 改行を防ぐ */
    overflow: hidden;   /* オーバーフローを隠す */
    text-overflow: ellipsis; /* オーバーフロー部分に省略記号を表示 */
}

.first-formset {
    margin-top: 0 !important;
}

hr {
    border: none;  /* borderプロパティをリセット */
    border-top: 1px solid #000;  /* 黒色の実線、2pxの太さに設定 */
    margin: 0;
    padding: px;
    opacity: 1;  /* 完全に不透明に設定 */

}

/* 全ての.rowの余白を削除 */
.row {
    margin: 0 !important;
}

/* 全ての.colの余白を削除 */
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    padding: 0 !important;
}
/* デフォルトのフォントサイズ */
.header-text {
    white-space: nowrap;
    font-size: 16px;
}

/* ヘッダーテキスト使用食材～調整履歴。画面の幅が 768px 以下の場合のフォントサイズ */
@media (max-width: 768px) {
    .header-text {
        font-size: 14px;
    }
}

/* 画面の幅が 576px 以下の場合のフォントサイズ */
@media (max-width: 576px) {
    .header-text {
        font-size: 12px;
    }
}
/* デフォルトのフォームの高さ */
.form-control {
    height: 30px;
}


.recipe-name-label {
    white-space: nowrap;
    font-size: 18px; /* デフォルトのフォントサイズを少し大きく */
}
/* 料理名フォーム */
.larger-input {
    font-size: 22px; 
    height: 40px;    
}
/* 完成後の食材フォームテキスト */
.completed-text {
    font-size: 18px; /* この値は既存のスタイルに合わせて調整してください */
    
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;  /* テキストを左詰めにする */
    white-space: pre-wrap;
   
    }
/*完成後の使用量テキスト */
.text-right-when-completed {
    font-size: 20px;
    text-align: right;
}
/* 完成後の単位テキスト */
.completed-text2 {
    font-size: 18px; /* この値は既存のスタイルに合わせて調整してください */
    /* この値はテキストの位置が正しくなるように調整してください */
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;  /* テキストを左詰めにする */
    white-space: pre-wrap;
    margin-left: 4px;
    margin-top: 2px;
    }

/*完成後のヘッダーテキスト（使用食材、使用量） */
.header-text-shifted {
    margin-left: -20px;
    margin-top: 7px;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
}




.procedure-textarea {
    font-size: 17px;
    width: 100%;
    height: auto;
    resize: none; /* ユーザによるリサイズを無効にする */
    overflow-y: hidden; /* 垂直方向のスクロールバーを非表示にする */
}

.procedure-textarea:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}
.adjustable-font-size {
    font-size: 16px;
}

@media (max-width: 768px) {
    .adjustable-font-size {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .adjustable-font-size {
        font-size: 10px;
    }
}

.img-preview {
    width: 100%;
    max-width: 100px;  /* この値はお好みで調整してください */
    cursor: pointer;  /* 画像がクリック可能であることを示す */
    height: auto;
    display: block;
    margin-top: 10px;
}

/* モーダルの基本スタイル */
.modal {
    display: flex;
    align-items: center; /* これがキー: ビューポートの中央にモーダルコンテンツを垂直に配置します */
    justify-content: center; /* ビューポートの中央にモーダルコンテンツを水平に配置します */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000;
    overflow-y: auto; /* 必要に応じてモーダルのスクロールを許可 */
}



.modal-content {
    position: relative;
    width: 80%;
    max-width: 800px;
    height: 100px;
}


.modal-content img {
    width: 100%;
    max-width: 100%;
    display: block;
}


.image-upload-container {
    display: flex;
    gap: 20px;
    align-items: center; /* 垂直方向の中央揃え */
    justify-content: center; /* 水平方向の中央揃え */
  }
  
  .image-upload-box {
    width: 100px; /* プレビュー画像のサイズと同じ */
    height: 100px;
    border: 1px solid #ccc;
    position: relative;
    cursor: pointer;
  }
  
  .empty-image-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 12px;
    color: #666;
  }
  
  .img-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 0px;
  }
  
/* デフォルトのフォントサイズ */
.recipe-dates {
    font-size: 16px;
}

/* 画面幅が768px以下の場合のフォントサイズ */
@media (max-width: 768px) {
    .recipe-dates {
        font-size: 14px;
    }
}

/* 画面幅が576px以下の場合のフォントサイズ */
@media (max-width: 576px) {
    .recipe-dates {
        font-size: 12px;
    }
}

/* 保存ボタン */
.save-button {
    transform: scale(1.2);
    background-color: #99e692 ;
    border: 1px solid rgb(139, 129, 129);
    color: #000000 !important;
    font-size: 16px;
    width: 100px;  /* 横幅を100pxに設定 */
    margin-left: -170px; /* 左から20pxのマージンを設定 */
    margin-bottom: -30px;
}

    
    
@media (max-width: 768px) {
    .save-button {
        margin-left: -110px;
    }
}

@media (max-width: 576px) {
    .save-button {
        margin-left: -70px;
    }
}



  
  

  .btn-primary2 {
    background-color: #c8d6f4 !important; 
    border-color: #9bb5d7 !important;
    color: #000000 !important; 
   
}
/* ボタンがホバーされたときのエフェクト */
.btn-primary2:hover {
    background-color: #dde3f1 !important;  /* ちょっと明るく */
    border-color: #acbed6 !important;
}

/* ボタンがアクティブ（クリックされた）ときのエフェクト */
.btn-primary2:active {
    transform: scale(0.95); /* ボタンを少し縮小 */
    transition: transform 0.1s; /* クリックエフェクトの速度調整 */
}
/* 履歴保存ボタンのテキストサイズを調整 */
.btn.adjustable2-font-size {
    font-size: 16px;
    padding-left: 10px;  /* 左側の内部スペース */
    padding-right: 10px; /* 右側の内部スペース */
}


@media (max-width: 768px) {
    .btn.adjustable2-font-size {
        font-size: 11px;
    }
}

@media (max-width: 576px) {
    .btn.adjustable2-font-size {
        font-size: 8px;
    }
}
.button-text {
    visibility: visible;
  }
  
  .shopping-mode .button-text {
    visibility: hidden;
  }
  
  .shopping-mode::before {
    content: "完了";
    visibility: visible;
  }
  
  .modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.centered-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

  

.my-modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .my-modal-content {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    
    width: 100%;
    height: 80%;
    overflow: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: auto;
  }
  
  