.newtop-page {
    background-image: url('./images/newtoppage.png'); /* こちらは任意の背景色に変更してください */
    min-height: 100vh; /* 画面の高さいっぱいに広がるように設定 */
  margin: 0; /* 外側の余白をなくす */
  padding: 0; /* 内側の余白をなくす */
  box-sizing: border-box; /* パディングとボーダーを要素の幅と高さに含める */
    background-position: center; /* 画像を中央に配置する */
    background-repeat: no-repeat; /* 画像を繰り返さない */
  }





  .nt-ht {
    font-size: 3rem; /* 例としてのサイズです。必要に応じて調整してください。 */
    font-weight: bold;
  }
  .nt-ht2 {
    font-size: 1.5rem; /* 例としてのサイズです。必要に応じて調整してください。 */
    font-weight: bold;
  }
  
  .auth-link {
    display: block; /* リンクをブロック要素のように全幅を使うようにします。 */
    margin: 10px 0; /* 各リンクの上下にスペースを追加します。 */
    font-size: 1.8rem; /* 例としてのサイズです。必要に応じて調整してください。 */
    padding: 10px; /* リンクを大きく見せます。 */
    text-align: center; /* リンク内のテキストを中央揃えにします。 */
  }
