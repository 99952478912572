.procedure-textarea {
    /* 既存のスタイルルール */
    white-space: pre-wrap;  /* 改行と空白を保持する */
  height: 150%; 
}
.form2 {
    height: 150%; 
    width: 100%; 
    border-radius: 10px; /* 枠に丸みを持たせる */
    font-size: 20px; /* フォーム内のテキストサイズを変える */  
    border: 1px solid #a2c0dd; /* シンプルな枠線を設定 */
  }