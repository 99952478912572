.forgot-password-container {
    background-image: url('../images/signin.png'); /* こちらは任意の背景色に変更してください */
    min-height: 100vh; /* 画面の高さいっぱいに広がるように設定 */
  margin: 0; /* 外側の余白をなくす */
  padding: 0; /* 内側の余白をなくす */
  box-sizing: border-box; /* パディングとボーダーを要素の幅と高さに含める */
    background-position: center; /* 画像を中央に配置する */
    background-repeat: no-repeat; /* 画像を繰り返さない */
  }
  .forgotpass-form{
    max-width: 550px;
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: -10; /* 最後のFormGroupからのマージンを取り除く */
  }
  .signin-form-group2{
    max-width: 550px;
    margin: 0 auto;
    padding: 1rem;
    margin-top: -40px; /* パスワードのフォームを上に10ピクセル移動させる */
  }

  .forgotpass-label {
    /* Labelのスタイル */
    font-size: 1.7rem; /* 例: フォントサイズを1.2remに設定 */
  }
  
  .forgotpass-input {
    height: 2.5rem; /* 高さの値を変更して希望のサイズに調整 */
    font-size: 1.2rem; /* 例: フォントサイズを1remに設定 */
  }
  
  .forgotpass-button {
    /* Buttonのスタイル */
    font-size: 1.5rem; /* 例: フォントサイズを1remに設定 */
    padding: 0.5rem 1rem; /* 例: 上下のパディング0.5rem、左右のパディング1remに設定 */
    margin-top: -20px; 
  }