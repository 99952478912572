.home-container {
    background-image: url('./images/home.png'); /* こちらは任意の背景色に変更してください */
    min-height: 100vh; /* 画面の高さいっぱいに広がるように設定 */
  margin: 0; /* 外側の余白をなくす */
  padding: 0; /* 内側の余白をなくす */
  box-sizing: border-box; /* パディングとボーダーを要素の幅と高さに含める */
    background-position: center; /* 画像を中央に配置する */
    background-repeat: no-repeat; /* 画像を繰り返さない */
  }
  
.page-title {
  font-size: 2em; /* h1に相当するフォントサイズ */
  margin-bottom: -40px; /* 上部の余白をなくし、下に余白を設定 */
 

  font-weight: 600;

}
.pt-outlined {
  color: rgb(5, 5, 5); /* テキストの色 */
  text-shadow:
    -1px -1px 0 #fdfcfc,
     1px -1px 0 #fafafa,
    -1px  1px 0 #fcfbfb,
     1px  1px 0 #f9f7f7; /* 黒の影を各方向に設定 */
}

  

  .homebuttons-container {
    transform: scale(0.8);
    background-color: #7de49c;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2列のレイアウト */
    gap: 20px; /* ボタン間の間隔 */
    padding: 20px; /* コンテナのパディング */
    max-width: 600px; /* オプション: コンテナの最大幅 */
    margin: 0 auto; /* 中央寄せ */
    max-height: 90vh; /* 画面の高さの90%を最大高さとする */
    
  }
  
  .home-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    background-color: #f0f0f0; /* ボタンの背景色 */
    border: 2px solid #ddd; /* ボタンの境界線 */
    border-radius: 10px; /* 角を丸く */
    padding: 10px;
    color: #333; /* ボタン内のテキストカラー */
    overflow: hidden; /* 画像がはみ出さないようにする */
  }
  
  .button-icon {
    width: 80px; /* イラストのサイズ */
    height: 80px; /* イラストのサイズ */
    margin-bottom: 10px; /* アイコンとテキストの間隔 */
    background-size: cover; /* 背景画像をカバーするように調整 */
    background-position: center; /* 背景画像を中央に配置 */
  }
  
  .button-text {
    font-size: 16px; /* テキストのサイズを設定 */
  }
  
  /* 各アイコンに対応する背景画像を設定します。
     実際のイラストのパスには、プロジェクト内の実際のイメージパスを指定してください。 */
  
     .icon-path-kitchen {
        background-image: url('./images/1.png'); /* myキッチンのアイコン */
      }
      
      .icon-path-community {
        background-image: url('./images/2.png'); /* みんなのごはん堂のアイコン */
      }
      
      .icon-path-submit {
        background-image: url('./images/3.png'); /* レシピを送るのアイコン */
      }
          
      .icon-path-receive-recipes {
        background-image: url('./images/4.png'); /* 設定のアイコン */
      }
      .icon-path-shopping {
        background-image: url('./images/5.png'); /* 買い物リストのアイコン */
      }
      .icon-path-profile {
        background-image: url('./images/6.png'); /* お知らせのアイコン */
      }
      
      .icon-path-contact {
        background-image: url('./images/7.png'); /* 問い合わせのアイコン */
      }
      
      .icon-path-donate {
        background-image: url('./images/8.png'); /* 円と念を送るのアイコン */
      }
  
  