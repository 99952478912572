.shop-form1 {
  height: 100%; 
  width: 100%; 
  border-radius: 10px; /* 枠に丸みを持たせる */
  font-size: 20px; /* フォーム内のテキストサイズを変える */  
  border: 1px solid #a2c0dd; /* シンプルな枠線を設定 */
}

.shop-form2 {
  height: 100%; 
  width: 100%; 
  border-radius: 10px; /* 枠に丸みを持たせる */
  font-size: 20px; /* フォーム内のテキストサイズを変える */  
  border: 1px solid #a2c0dd; /* シンプルな枠線を設定 */
}
.shop-form3 {
  height: 100%; 
  width: 100%; 
  border-radius: 10px; /* 枠に丸みを持たせる */
  font-size: 20px; /* フォーム内のテキストサイズを変える */  
  border: 1px solid #a2c0dd; /* シンプルな枠線を設定 */
}

.shop-redbtn {
  height: 100%; 
  width: 100%; 
  border-radius: 10px; /* 枠に丸みを持たせる */
  font-size: 10px; /* フォーム内のテキストサイズを変える */  
  margin-top: 5px;
  border:1px solid rgb(94, 82, 82);
  white-space: nowrap;
  padding: 0px 0px;
}
.shopgreenbtn1 {
  transform: scale(1.2);
  padding: 5px 1px;
  width: 70%; 
  height: 80%; 
  margin-right: -20px;
  margin: 5px;  /* 上下左右に5pxのマージンを追加 */
  top: -3px; /* この値を調整して要素を上に動かします */
  border: 1px solid rgb(139, 129, 129);
  background-color: #99e692; 
 
}
.shopgreenbtn2 {
transform: scale(1.2);
padding: 5px 1px;
width: 70%; 
height: 80%; 
margin-right: -20px;
margin: 5px;  /* 上下左右に5pxのマージンを追加 */
top: -3px; /* この値を調整して要素を上に動かします */
border: 1px solid rgb(139, 129, 129);
background-color: #99e692 ;

}

/* ボタンがホバーされたときのエフェクト */
.shopgreenbtn2:hover {
background-color: #88d682 !important; /* ちょっと明るく */
border-color: #d0d0cc !important;

}

/* ボタンがアクティブ（クリックされた）ときのエフェクト */
.shopgreenbtn2:active {
transform: scale(0.95); /* ボタンを少し縮小 */
transition: transform 0.1s; /* クリックエフェクトの速度調整 */
border: 1px solid rgb(139, 129, 129);
}

.shophr1 {
margin: 10px;
}
.shopcontainer {
    transform: scale(0.9);  /* この値は必要に応じて調整してください。1より小さい値で全体を縮小します。 */
    transform-origin: top;
    margin-top: 10px;
}
/* 横向きのスタイル */
@media screen and (orientation: landscape) {
  .shopcontainer {
    transform: scale(0.9);
    transform-origin: top;
    margin-top: 10px;
}
  .shopgreenbtn1 {
    transform: scale(1.2);
    padding: 5px 1px;
    width: 45%; 
    height: 80%; 
    margin-right: -20px;
    margin: 5px;  /* 上下左右に5pxのマージンを追加 */
    top: -3px; /* この値を調整して要素を上に動かします */
    border: 1px solid rgb(139, 129, 129);
    background-color: #99e692; 
    margin-top: 5px;
  }
  .shopgreenbtn2 {
  transform: scale(1.2);
  padding: 5px 1px;
  width: 45%; 
  height: 80%; 
  margin-right: -20px;
  margin: 5px;  /* 上下左右に5pxのマージンを追加 */
  top: -3px; /* この値を調整して要素を上に動かします */
  border: 1px solid rgb(139, 129, 129);
  background-color: #99e692 ;
  margin-bottom: 5px;
  }
  .shop-redbtn {
    height: 100%; 
    width: 100%; 
    border-radius: 10px; /* 枠に丸みを持たせる */
    font-size: 15px; /* フォーム内のテキストサイズを変える */  
    margin-top: 5px;
    border:1px solid rgb(94, 82, 82);
    white-space: nowrap;
  }


}
/* Surface Pro 7 の場合 */
@media screen and (min-width: 912px) and (max-width: 912px) and (min-height: 1368px) and (max-height: 1368px) {
    .shopcontainer {
        transform: scale(0.9);
        transform-origin: top;
        margin-top: 10px;
    }
}

/* iPad Air の場合 */
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
    .shopcontainer {
        transform: scale(0.9);
        transform-origin: top;
        margin-top: 10px;
    }
}

