
.canbtn, .okbtn {
  /* margin-bottom: 0px; (marginは下で再定義されています) */
  padding: 10px; /* 内側の余白 */
  margin: 0px;  /* 外側の余白 */
}

.okbtn {
  padding: 11px; /* 内側の余白 */
}


.form1 {
  height: 150%; 
  width: 100%; 
  border-radius: 10px; /* 枠に丸みを持たせる */
  font-size: 16px; /* フォーム内のテキストサイズを変える */  
  border: 1px solid #a2c0dd; /* シンプルな枠線を設定 */
}



