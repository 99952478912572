.imagemodal-content {
    transform: scale(0.95);
    position: absolute;
    height: 90%;
    width: 90%;
    }
    .button-container {
        transform: scale(0.75);
        position: absolute;
        right: 55px;
        bottom: 0px;
        display: flex; 
        flex-direction: row;
        gap: 50px;
        
        padding: 10px;  /* パディングを追加 */
        border-radius: 5px;  /* 角を丸く */
        text-align: left;  /* テキストを左揃え */
        white-space: nowrap;
      }
    /* 横向きのスタイル */
  @media screen and (orientation: landscape) {
    .button-container {
        flex-direction: column;
        margin-right: -70px;
        width: auto;  /* 横幅を自動に */
        white-space: normal;  
    }
  }
  /* Surface Pro 7 の場合 */
  @media screen and (min-width: 912px) and (max-width: 912px) and (min-height: 1368px) and (max-height: 1368px) {
    .imagemodal-content {
        transform: scale(0.8);
        transform-origin: top;
        margin-left: 40px;
        
    }
    .button-container {
        flex-direction: row;
        margin-right: -20px;
        width: auto;  /* 横幅を自動に */
        white-space: normal;  
        margin-bottom: 400px;
    }
}
/* Surface Pro 7 の場合、横向き */
@media screen and (min-width: 1368px) and (max-width: 1368px) and (min-height: 912px) and (max-height: 912px) {
    .imagemodal-content {
        transform: scale(0.81);  /* スケーリングを調整 */
        transform-origin: top;  /* 変換の原点を調整 */
        margin-left: 20px;  /* 左マージンを調整 */
    }
    .button-container {
        flex-direction: row;
        margin-right: -20px;
        width: auto;  /* 横幅を自動に */
        white-space: normal;  
        margin-bottom: 80px;
    }
}

/* iPad Air の場合 */
@media screen and (min-width: 820px) and (max-width: 820px) and (min-height: 1180px) and (max-height: 1180px) {
     .imagemodal-content {
        transform: scale(0.8);
        transform-origin: top;
        margin-left: 40px;
        
    }
    .button-container {
        flex-direction: row;
        margin-right: -20px;
        width: auto;  /* 横幅を自動に */
        white-space: normal;  
        margin-bottom: 300px;
    }
}
/* iPad Air の場合、横向き */
@media screen and (min-width: 1180px) and (max-width: 1180px) and (min-height: 820px) and (max-height: 820px) {
    .imagemodal-content {
        transform: scale(0.81);  /* スケーリングを調整 */
        transform-origin: top;  /* 変換の原点を調整 */
        margin-left: 20px;  /* 左マージンを調整 */
    }
    .button-container {
        flex-direction: row;
        margin-right: -20px;
        width: auto;  /* 横幅を自動に */
        white-space: normal;  
        margin-bottom: 80px;
    }
}
  
  .close-button, .delete-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    overflow: hidden;  /* ボタン内のテキストがはみ出さないように */
    text-overflow: ellipsis;  /* テキストがはみ出る場合は省略記号を表示 */
  }
  
  .close-button {
    background-color: rgb(99, 99, 250);
    color: white;
  }
  
  .delete-button {
    
    background-color: red;
    color: white;
  }
  
  

  